import { MDXProvider } from "@mdx-js/react";
import { Collapse } from "antd";
import type {
  GatsbyBrowser,
  GatsbySSR,
  WrapRootElementBrowserArgs,
  WrapRootElementNodeArgs,
} from "gatsby";
import React from "react";

import PrismWrapper from "./src/components/docs/PrismWrapper";

const { Panel } = Collapse;
const components = {
  pre: PrismWrapper,
  Collapse: Collapse,
  Panel: Panel,
};

export const wrapMDX:
  | GatsbyBrowser["wrapRootElement"]
  | GatsbySSR["wrapRootElement"] = ({
  element,
}: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
