import { Highlight, themes } from "prism-react-renderer";
import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
  position: relative;
`;

const Pre = styled.pre`
  background: #1e1e1e;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  margin: 3rem 0;
  font-size: 0.9rem;
  font-family: "Courier New", Courier, monospace;
  overflow-x: scroll;
  .token-line {
    line-height: 1.5;
  }
  .code-tab {
    position: absolute;
    top: 0;
    right: 5%;
    color: rgb(156, 220, 254);
    font-size: 1rem;
    font-weight: 700;
    transform: translateY(-100%);
    text-transform: uppercase;
    padding: 0.05rem 0.85rem 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #1e1e1e;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrismWrapper = ({ children }: any) => {
  const className = children.props.className;
  const language = className.split("-")[1];

  return (
    <Highlight
      code={children.props.children.trim()}
      language={language == "english" ? "" : language}
      theme={language == "english" ? themes.github : themes.vsDark}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Container>
          <Pre className={className} style={style}>
            {language !== "english" && (
              <div className="code-tab">{language}</div>
            )}
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </Pre>
        </Container>
      )}
    </Highlight>
  );
};

export default PrismWrapper;
