exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-1-introduction-1-what-is-deployment-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/1-Introduction/1-what-is-deployment.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-1-introduction-1-what-is-deployment-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-1-introduction-2-why-is-deployment-needed-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/1-Introduction/2-why-is-deployment-needed.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-1-introduction-2-why-is-deployment-needed-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-2-runner-installation-1-architecture-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/2-Runner Installation/1-architecture.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-2-runner-installation-1-architecture-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-2-runner-installation-2-local-setup-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/2-Runner Installation/2-local-setup.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-2-runner-installation-2-local-setup-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-2-runner-installation-3-aws-setup-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/2-Runner Installation/3-aws-setup.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-2-runner-installation-3-aws-setup-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-3-infrastructure-1-static-website-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/3-Infrastructure/1-static-website-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-3-infrastructure-1-static-website-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-3-infrastructure-2-web-service-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/3-Infrastructure/2-web-service-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-3-infrastructure-2-web-service-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-1-adding-a-repository-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/1-adding-a-repository.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-1-adding-a-repository-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-10-automatic-deployment-on-git-push-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/10-automatic-deployment-on-git-push.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-10-automatic-deployment-on-git-push-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-11-creating-preview-deployments-on-pull-requests-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/11-creating-preview-deployments-on-pull-requests.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-11-creating-preview-deployments-on-pull-requests-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-12-getting-alerts-on-slack-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/12-getting-alerts-on-slack.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-12-getting-alerts-on-slack-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-2-creating-an-environment-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/2-creating-an-environment.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-2-creating-an-environment-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-3-deploying-a-static-website-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/3-deploying-a-static-website.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-3-deploying-a-static-website-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-4-deploying-a-web-service-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/4-deploying-a-web-service.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-4-deploying-a-web-service-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-5-adding-custom-domains-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/5-adding-custom-domains.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-5-adding-custom-domains-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-6-adding-response-headers-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/6-adding-response-headers.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-6-adding-response-headers-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-7-checking-build-logs-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/7-checking-build-logs.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-7-checking-build-logs-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-8-checking-application-logs-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/8-checking-application-logs.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-8-checking-application-logs-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-9-deleting-a-deployment-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/4-Dashboard/9-deleting-a-deployment.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-4-dashboard-9-deleting-a-deployment-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-1-introduction-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/5-Role Based Access Controls/1-introduction.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-1-introduction-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-2-creating-roles-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/5-Role Based Access Controls/2-creating-roles.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-2-creating-roles-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-3-inviting-users-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/5-Role Based Access Controls/3-inviting-users.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-3-inviting-users-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-4-sharing-deployments-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/5-Role Based Access Controls/4-sharing-deployments.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-4-sharing-deployments-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-5-sharing-environments-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/5-Role Based Access Controls/5-sharing-environments.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-5-role-based-access-controls-5-sharing-environments-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-1-how-to-get-free-aws-credits-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/1-how-to-get-free-aws-credits.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-1-how-to-get-free-aws-credits-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-10-how-to-deploy-vuejs-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/10-how-to-deploy-vuejs-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-10-how-to-deploy-vuejs-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-11-how-to-deploy-docusaurus-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/11-how-to-deploy-docusaurus-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-11-how-to-deploy-docusaurus-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-12-how-to-deploy-astro-app-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/12-how-to-deploy-astro-app-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-12-how-to-deploy-astro-app-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-13-how-to-deploy-rust-web-service-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/13-how-to-deploy-rust-web-service-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-13-how-to-deploy-rust-web-service-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-2-how-to-use-different-aws-accounts-for-test-and-production-environments-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/2-how-to-use-different-aws-accounts-for-test-and-production-environments.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-2-how-to-use-different-aws-accounts-for-test-and-production-environments-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-3-how-to-deploy-a-reactjs-app-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/3-how-to-deploy-a-reactjs-app-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-3-how-to-deploy-a-reactjs-app-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-4-how-to-deploy-gatsbyjs-app-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/4-how-to-deploy-gatsbyjs-app-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-4-how-to-deploy-gatsbyjs-app-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-5-how-to-deploy-go-web-service-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/5-how-to-deploy-go-web-service-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-5-how-to-deploy-go-web-service-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-6-how-to-deploy-nextjs-app-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/6-how-to-deploy-nextjs-app-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-6-how-to-deploy-nextjs-app-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-7-how-to-deploy-nodejs-web-service-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/7-how-to-deploy-nodejs-web-service-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-7-how-to-deploy-nodejs-web-service-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-8-how-to-deploy-python-web-service-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/8-how-to-deploy-python-web-service-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-8-how-to-deploy-python-web-service-on-aws-mdx" */),
  "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-9-how-to-deploy-ruby-on-rails-app-on-aws-mdx": () => import("./../../../src/templates/docs-template.tsx?__contentFilePath=/Users/ankit/Developer/deployment/website-svc/src/docs/6-Guides/9-how-to-deploy-ruby-on-rails-app-on-aws.mdx" /* webpackChunkName: "component---src-templates-docs-template-tsx-content-file-path-src-docs-6-guides-9-how-to-deploy-ruby-on-rails-app-on-aws-mdx" */),
  "component---src-templates-redirect-template-tsx": () => import("./../../../src/templates/redirect-template.tsx" /* webpackChunkName: "component---src-templates-redirect-template-tsx" */)
}

