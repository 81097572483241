/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import type { GatsbyBrowser } from "gatsby";

import { wrapMDX } from "./root-mdx";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = wrapMDX;
